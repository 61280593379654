import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import { isEmpty } from "lodash"
import { useMutation } from "@apollo/client"
import { v4 } from "uuid"
import { toast } from "react-toastify"

import Input from "components/Input/Input"
import Checkbox from "components/Checkbox/Checkbox"
import Button from "components/Button/Button"

import REGISTER_CUSTOMER from "../../mutations/register"
import {
  isUserValidated,
  // setAuth
} from "utils/functions"
import validateAndSanitizeRegisterForm from "validator/register"

const RegisterForm = ({ setLoggedIn, typeB2B }) => {
  const isBrowser = typeof window !== "undefined"

  const [legal, setLegal] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [companyNIP, setCompanyNIP] = useState("")
  const [companyName, setCompanyName] = useState("")

  const billing = {
    company: companyName + ", NIP: " + companyNIP,
  }

  // Check if the user is validated already and redirect
  if (isBrowser) {
    const userValidated = isUserValidated()

    if (!isEmpty(userValidated)) {
      navigate("/moje-konto/")
    }
  }

  // Sets client side error.
  const setClientSideError = validationResult => {
    if (validationResult.errors.password) {
      toast.error(validationResult.errors.password)
    }
    if (validationResult.errors.email) {
      toast.error(validationResult.errors.email)
    }
    if (validationResult.errors.firstName) {
      toast.error(validationResult.errors.firstName)
    }
    if (validationResult.errors.lastName) {
      toast.error(validationResult.errors.lastName)
    }
  }

  // Register Mutation.
  const [register, { loading: registerLoading, error: registerError }] =
    useMutation(REGISTER_CUSTOMER, {
      variables: {
        input: {
          clientMutationId: v4(),
          firstName,
          lastName,
          password,
          email,
          billing,
        },
      },
      onCompleted: data => {
        if (!isEmpty(registerError)) {
          toast.error("Wystąpił błąd")
          console.warn(registerError.graphQLErrors[0].message)
        }

        handleRegisterSuccess()

        // const {
        //   registerCustomer: { customer },
        // } = data

        // const authData = {
        //   authToken: customer.jwtAuthToken,
        //   user: customer,
        // }

        // setAuth(authData)
        // setLoggedIn(true)
      },
      onError: error => {
        if (error) {
          if (!isEmpty(error)) {
            toast.error("Wystąpił błąd")
            console.warn(error.graphQLErrors[0].message)
          }
        }
      },
    })

  // Handles user registration.
  const handleRegister = async event => {
    if (isBrowser) {
      event.preventDefault()

      // Validation and Sanitization.
      const validationResult = validateAndSanitizeRegisterForm({
        firstName,
        lastName,
        email,
        password,
      })

      // If the data is valid.
      if (validationResult.isValid) {
        setFirstName(validationResult.sanitizedData.firstName)
        setLastName(validationResult.sanitizedData.lastName)
        setPassword(validationResult.sanitizedData.password)
        setEmail(validationResult.sanitizedData.email)
        register()
      } else {
        setClientSideError(validationResult)
      }
    }
  }

  // Handle Register success.
  const handleRegisterSuccess = () => {
    setFirstName("")
    setLastName("")
    setPassword("")
    setEmail("")
    setCompanyNIP("")
    setCompanyName("")
    toast.success(
      "Twoje konto zostało właśnie utworzone. Aktywuj je kilkając w link aktywacyjny który otrzymasz na swój adres e-mail."
    )
    navigate("/")
  }

  return (
    <form className="form" onSubmit={e => handleRegister(e)}>
      <div className="row">
        <div className="col-sm-6">
          <Input
            type="text"
            name="firstName"
            placeholder="Imię"
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
          />
        </div>
        <div className="col-sm-6">
          <Input
            type="text"
            name="lastName"
            placeholder="Nazwisko"
            value={lastName}
            onChange={e => setLastName(e.target.value)}
          />
        </div>
        <div className="col-sm-6">
          <Input
            type="email"
            name="email"
            placeholder="E-mail"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </div>
        <div className="col-sm-6">
          <Input
            type="password"
            name="password"
            placeholder="Hasło"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </div>
      </div>
      {typeB2B && (
        <div className="row">
          <div className="col-sm-6">
            <Input
              type="text"
              name="companyNIP"
              placeholder="NIP"
              value={companyNIP}
              onChange={e => setCompanyNIP(e.target.value)}
            />
          </div>
          <div className="col-sm-6">
            <Input
              type="text"
              placeholder="Nazwa firmy"
              name="companyName"
              value={companyName}
              onChange={e => setCompanyName(e.target.value)}
            />
          </div>
        </div>
      )}
      <div className="row form__cta">
        <div className="col-md-6">
          <Checkbox
            name="legal"
            checked={legal}
            onChange={() => setLegal(!legal)}
          >
            Chcę zarejestrować konto w sklepie MEMTECH Sp. z o.o. Sp. k. i
            akceptuję <Link to="/regulamin/">regulamin sklepu</Link> oraz{" "}
            <Link to="/polityka-prywatnosci/">politykę prywatności</Link>.
          </Checkbox>
        </div>
        <div className="col-md-6 text-right">
          <Button
            type="submit"
            disabled={!legal || registerLoading ? "disabled" : ""}
          >
            {registerLoading ? "Przesyłanie danych..." : "Zarejestruj się"}
          </Button>
        </div>
      </div>
    </form>
  )
}

export default RegisterForm
