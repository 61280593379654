import "styles/pages/b2b.scss"

import React, { useState, useEffect } from "react"
import Layout from "components/layout"
import Seo from "components/seo"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"
import PageHeader from "components/PageHeader/PageHeader"
import PageSubHeader from "components/PageSubHeader/PageSubHeader"
import Button from "components/Button/Button"
import RegisterForm from "components/RegisterForm/RegisterForm"
import { isUserLoggedIn } from "utils/functions"

import B2bIco1 from "assets/images/b2b-ico-1.svg"
import B2bIco2 from "assets/images/b2b-ico-2.svg"
import B2bIco3 from "assets/images/b2b-ico-3.svg"

const B2b = () => {
  const title = "Zakupy hurtowe"

  const [loggedIn, setLoggedIn] = useState(false)

  useEffect(() => {
    const auth = isUserLoggedIn()

    if (!auth) {
      setLoggedIn(true)
    }
  }, [loggedIn])

  return (
    <Layout>
      <Seo title={title} />
      <Breadcrumbs title={title} />

      <section className="b2b">
        <div className="container-fluid">
          <PageHeader title={title} />
          <PageSubHeader title="Dlaczego warto?" />

          <div className="row">
            <div className="col-md-4">
              <div className="b2b__item">
                <div className="b2b__item-image">
                  <img src={B2bIco1} alt="" />
                </div>
                <PageSubHeader title="Doświadczenie" />
                <p>
                  Istniejemy na rynku IT nieprzerwanie od 2001 roku – przez ten
                  czas zdobyliśmy mnóstwo cennego doświadczenia dzięki któremu
                  możemy świadczyć obsługę na najwyższym poziomie.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="b2b__item">
                <div className="b2b__item-image">
                  <img src={B2bIco2} alt="" />
                </div>
                <PageSubHeader title="Rzetelność" />
                <p>
                  Dostarczamy tylko sprawdzone rozwiązania. Gwarantujemy pełen
                  profesjonalizm i poufność powierzonych danych.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="b2b__item">
                <div className="b2b__item-image">
                  <img src={B2bIco3} alt="" />
                </div>
                <PageSubHeader title="Ukierunkowanie na sukces" />
                <p>
                  Będąc na rynku już blisko dwie dekady cały czas rozwijamy
                  zakres naszych usług i poszerzamy swoje umiejętności i wiedzę,
                  tak aby oferowany przez nas sprzęt był najwyższej jakości.
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-7">
              <PageSubHeader title="Formularz rejestracyjny" />
              <RegisterForm setLoggedIn={setLoggedIn} typeB2B />
            </div>

            <div className="col-xl-4 offset-xl-1">
              <PageSubHeader title="Potrzebujesz więcej informacji?" />
              <p>
                Jeżeli potrzebujesz dodatkowych informacji lub po prostu
                chciałbyś się z nami skonsultować gorąco zapraszamy do kontaktu.
              </p>
              <Button type="link" to="/kontakt/" className="button--bordered">
                Kontakt
              </Button>
              <PageSubHeader
                title="Masz już konto?"
                className="page__sub-header--less-m"
              />
              <Button type="link" to="/logowanie/" className="button--bordered">
                Zaloguj się
              </Button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default B2b
